import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { transition: 'slide-right' },
  },
  {
    path: '/sobre',
    name: 'sobre',
    component: () => import(/* webpackChunkName: "sobre" */ '../views/Sobre.vue'),
    meta: { transition: 'slide-right' },
  },
  {
    path: '/cursos',
    name: 'cursos',
    component: () => import(/* webpackChunkName: "cursos" */ '../views/Cursos.vue'),
    meta: { transition: 'slide-right' },
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue'),
    meta: { transition: 'slide-right' },
  },
  {
    path: '/blog-news/:id/:slug',
    name: 'blog-new',
    component: () => import(/* webpackChunkName: "blog-new" */ '../views/BlogNew.vue'),
    meta: { transition: 'slide-right' },
  },
  {
    path: '/informativo/remove/:email',
    name: 'info-remove',
    component: () => import(/* webpackChunkName: "info-remove" */ '../views/Info.vue'),
    meta: { transition: 'slide-right' },
  },
  {
    path: '/contato',
    name: 'contato',
    component: () => import(/* webpackChunkName: "contato" */ '../views/Contato.vue'),
    meta: { transition: 'slide-right' },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
