<template>
  <fade-transition :duration="600">
    <b-container>
      <top/>
        <router-view/>
      <div class="line-right"/>
    </b-container>
  </fade-transition>
</template>
<script>
import Top from './components/Top'
import {FadeTransition} from 'vue2-transitions'
export default {
  components:{
    Top,
    FadeTransition
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;800&display=swap');
body {
  font-family: 'Montserrat', sans-serif;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-color: #0E0C38;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 0;
  margin: 0;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.line-right{
  background-image: url('~@/assets/imgs/DOTS_RIGHT.svg');
  width: 2%;
  height: 31%;
  position: absolute;
  bottom: 0;
  right: 0;
}
body {
	animation: gradient 15s ease infinite;
	height: 100vh;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 100%;
	}
	100% {
		background-position: 0% 50%;
	}
}
</style>