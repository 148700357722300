import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentPost: null,
    otherPosts: null,
    allPosts: null,
  },
  getters: {
    getCurrentPost(state){
      return state.currentPost
    },
    getOthersPosts(state){
      return state.otherPosts
    },
    getAllPosts(state){
      return state.allPosts
    }
  },
  mutations: {
    SET_CURRENT_POST( state, payload){
      state.currentPost = payload
    },
    SET_OTHERS_POSTS( state, payload){
      state.otherPosts = payload
    },
    SET_ALL_POSTS( state, payload){
      state.allPosts = payload
    },
  },
  actions: {
    setCurrentPost(context,id){
      context.commit('SET_CURRENT_POST', id)
    },
    setOthersPosts(context,payload){
      context.commit('SET_OTHERS_POSTS', payload)
    },
    setAllPosts(context,payload){
      context.commit('SET_ALL_POSTS', payload)
    }
  },
})
