<template>
  <div>
    <nav id="nav" class="nav" role="navigation" v-if="$mq === 'sm' || $mq === 'xs'">
      <ul class="nav__menu" id="menu" tabindex="-1" aria-label="main navigation" hidden>
        <li class="nav__item"><router-link tag="a" :to="{ name: 'home' }" class="nav__link menu-list-mobile"><span @click="openMenu()">HOME</span></router-link></li>
        <li class="nav__item"><router-link tag="a" :to="{ name: 'sobre' }" class="nav__link menu-list-mobile"><span @click="openMenu()">SOBRE</span></router-link></li>
        <li class="nav__item"><router-link tag="a" :to="{ name: 'cursos' }" class="nav__link menu-list-mobile"><span @click="openMenu()">CURSOS</span></router-link></li>
        <li class="nav__item"><router-link tag="a" :to="{ name: 'blog' }" class="nav__link menu-list-mobile"><span @click="openMenu()">BLOG</span></router-link></li>
        <li class="nav__item"><router-link tag="a" :to="{ name: 'contato' }" class="nav__link menu-list-mobile"><span @click="openMenu()">CONTATO</span></router-link></li>
      </ul>
      <button class="nav__toggle" role="button" aria-expanded="false" aria-controls="menu">
        <svg class="menuicon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
          <title>Menu</title>
          <g>
            <line class="menuicon__bar" x1="13" y1="16.5" x2="37" y2="16.5"/>
            <line class="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5"/>
            <line class="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5"/>
            <line class="menuicon__bar" x1="13" y1="32.5" x2="37" y2="32.5"/>
            <circle class="menuicon__circle" r="23" cx="25" cy="25" />
          </g>
        </svg>
      </button>
      <div class="splash"></div>
    </nav>
    <b-row align-v="center" v-if="$mq === 'sm' || $mq === 'xs'">
      <b-col cols="4" sm="4"></b-col>
      <b-col cols="5" sm="5">
        <img src="/img/LOGO.svg" class="logo">
      </b-col>
      <b-col cols="2" sm="2">
        
      </b-col>
    </b-row>
    <ul id="nav" v-if="$mq !== 'sm' && $mq !== 'xs'">
      <li>
        <img src="/img/LOGO.svg" class="logo">
      </li>
      <li class="line-top"></li>
      <li class="menu-item" :class="{ 'active': $route.name === 'home'}">
        <router-link tag="a" :to="{ name: 'home' }">HOME</router-link>
      </li>
      <li class="menu-item" :class="{ 'active': $route.name === 'sobre'}">
        <router-link tag="a" :to="{ name: 'sobre' }">SOBRE</router-link>
      </li>
      <li class="menu-item" :class="{ 'active': $route.name === 'cursos'}">
        <router-link tag="a" :to="{ name: 'cursos' }">CURSOS</router-link>
      </li>
      <li class="menu-item" :class="{ 'active': $route.name && $route.name.includes('blog')}">
        <router-link tag="a" :to="{ name: 'blog' }">BLOG</router-link>
      </li>
      <li class="menu-item" :class="{ 'active': $route.name === 'contato'}">
        <router-link tag="a" :to="{ name: 'contato' }">CONTATO</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data(){
    return {
      isMenuOpen: false,
    }
  },
  methods: {
    openMenu(){
      const menuToggle = document.querySelector('.nav__toggle');
      this.isMenuOpen = false
      // toggle a11y attributes and active class
      menuToggle.setAttribute('aria-expanded', String(this.isMenuOpen));
      menu.hidden = !this.isMenuOpen;
      nav.classList.toggle('nav--open');
    }
  },
  watch:{
    isMenuOpen(value){
      document.querySelector('body').style.overflow = value ? 'hidden' : 'unset'
    }
  },
  mounted() {
    const nav = document.querySelector('#nav');
    const menu = document.querySelector('#menu');
    const menuToggle = document.querySelector('.nav__toggle');
    // TOGGLE MENU ACTIVE STATE
    menuToggle.addEventListener('click', e => {
      e.preventDefault();
      this.isMenuOpen = !this.isMenuOpen;
      // toggle a11y attributes and active class
      menuToggle.setAttribute('aria-expanded', String(this.isMenuOpen));
      menu.hidden = !this.isMenuOpen;
      nav.classList.toggle('nav--open');
    });
    // TRAP TAB INSIDE NAV WHEN OPEN
    nav.addEventListener('keydown', e => {
      // abort if menu isn't open or modifier keys are pressed
      if (!this.isMenuOpen || e.ctrlKey || e.metaKey || e.altKey) {
        return;
      }
      // listen for tab press and move focus
      // if we're on either end of the navigation
      const menuLinks = menu.querySelectorAll('.nav__link');
      if (e.keyCode === 9) {
        if (e.shiftKey) {
          if (document.activeElement === menuLinks[0]) {
            menuToggle.focus();
            e.preventDefault();
          }
        } else if (document.activeElement === menuToggle) {
          menuLinks[0].focus();
          e.preventDefault();
        }
      }
    });
  },
}
</script>
<style lang="scss" scoped>
.line-top{
  margin-top: -20px !important;
  margin-right: 32px;
  margin-left: 16px;
  width: 300px !important;
  height: 40px;
  background-image: url('~@/assets/imgs/DOTS_TOP.svg');
  background-size: cover ;
}
.active{
  background-color: #0098DA;
  color: #ffffff;
}
ul{
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 20px;
  color: #ffffff;
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 0;
  margin: auto;
  .menu-item{
    padding: 0px 16px 0 16px;
    height: 90px;
    margin-top: -25px;
    display: flex;
    align-items: center;
    user-select: none;
    a{
      color: #FFFFFF !important;
      text-decoration: none !important;
    }
    &:not(.active) a:hover{
      color: #F0CC1C !important;
    }
  }
}
.btn{
  width: 40px !important;
}
.menu-icon{
  font-size: 30px;
  color: #ffffff !important;
  margin: 0 !important;
}
.menu-list-mobile{
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  // border-top: 1px solid #005b6d;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  color: #ffffff;
  margin-bottom: 0;
  &:hover{
    background-color: rgba(0,0,0, 0.1) !important;
  }
}
.logo{
  margin-top: 10px;
  max-height: 90px;
  margin-left: 20px;
}
.logo-mobile{
  margin-top: 20px;
  margin-bottom: 40px;
  max-height: 90px;
}
:root {
  --screen-width:     320px;
  --screen-height:    560px;
  --header-bg-color:  #673AB7;
  --splash-bg-color:  #368887;
}
@function circumference($r){
  $pi: 3.141592653;
  @return 2*$pi*$r;
}
.nav {
  // Toggle Button
  &__toggle {
    display:inline-block;
    position:absolute;
    z-index:10;
    padding:0;
    border:0;
    background:transparent;
    outline:0;
    right:15px;
    top:15px;
    cursor:pointer;
    border-radius:50%;
    transition:background-color .15s linear;
    
    &:hover,
    &:focus {
      background-color:rgba(0,0,0,.5);
    }
  }
  
  &__menu {
    display:flex;
    flex-direction:column;
    justify-content:center;
    height:var(--screen-height);
    position:relative;
    z-index:5;
    visibility:hidden;
  }
  
  &__item {
    opacity:0;
    transition: all .3s cubic-bezier(0.000, 0.995, 0.990, 1.000) .3s;
  }
  @for $i from 1 through 5 {
    &__item:nth-child(#{$i}){
      transform:translateY(-40px * $i);
    }
  }
  
  &__link {
    color:white;
    display:block;
    text-align:center;
    text-transform:uppercase;
    letter-spacing:5px;
    font-size:1.25rem;
    text-decoration:none;
    padding:1rem;
    
    &:hover,
    &:focus {
      outline:0;
      background-color:rgba(0,0,0,0.2);
    }
  }
}
.menuicon {
  display:block;
  cursor:pointer;
  color: white;
  
  transform:rotate(0deg);
  transition: .3s cubic-bezier(0.165, 0.840, 0.440, 1.000); 
  
  &__bar,
  &__circle {
    fill:none;
    stroke: currentColor;
    stroke-width:3;
    stroke-linecap:round;
  }
  &__bar {
    transform: rotate(0deg);
    transform-origin:50% 50%;
    transition: transform .25s ease-in-out;
  }
  &__circle {
    transition: stroke-dashoffset .3s linear .1s;
    stroke-dashoffset:circumference(23); // 23 is the <circle>'s radius
    stroke-dasharray:circumference(23);
  }
}
.splash {
  position:absolute;
  top:40px;
  right:40px;
  width: 1px;
  height: 1px;
  
  &::after {
    content:"";
    display:block;
    position:absolute;
    border-radius:50%;
    background-color:var(--splash-bg-color);
    
    // screen diameter can be 142vmax at most,
    // circle needs to be twice that size to cover it
    width:284vmax;
    height:284vmax;
    top:-142vmax;
    left:-142vmax;
    
    transform: scale(0);
    transform-origin:50% 50%;
    transition: transform .5s cubic-bezier(0.755, 0.050, 0.855, 0.060);
    
    // will-change tells the browser we plan to 
    // animate this property in the near future
    will-change:transform;
  }
}


.nav:target,
.nav--open {
  //scale the background circle to full size
  > .splash::after {
    // transform:scale(1);
  }
  
  //animate the menu icon
  .menuicon {
    color:white;
    transform:rotate(180deg);
    
    &__circle {
      stroke-dashoffset:0;
    }
    &__bar:nth-child(1),
    &__bar:nth-child(4) {
      opacity:0;
    }
    &__bar:nth-child(2) {
      transform: rotate(45deg);
    }
    &__bar:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
  
  //show the nav items
  .nav {
    &__menu {
      visibility:visible;
    }
  
    &__item {
      opacity:1;
      transform:translateY(0);
    }
  }
}
.nav__menu{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 0 !important;
  background-color: #2a2a72;
  background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
  opacity: 0.92;
  transition: all .15s linear;
}
</style>
<style>
#sidebar-1{
padding: 20px;
background-color: #abe9cd;
background-image: linear-gradient(360deg, #00697D 60%, #076188 100%);
opacity: 0.98;
}
.menu-btn{
  background-color: #0098DA !important;
  border: none !important;
  text-align: center !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}
</style>