import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueTheMask from 'vue-the-mask'
import VueMq from 'vue-mq'
import VueSweetalert2 from 'vue-sweetalert2';
import Transitions from 'vue2-transitions'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore  } from "firebase/firestore";
import 'sweetalert2/dist/sweetalert2.min.css';

import { BootstrapVue } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

const firebaseConfig = {
  apiKey: "AIzaSyA96k2ULtAq0uMw6cdEuVnOww3mwdYGLBs",
  authDomain: "heberlima-50b09.firebaseapp.com",
  projectId: "heberlima-50b09",
  storageBucket: "heberlima-50b09.appspot.com",
  messagingSenderId: "605244085717",
  appId: "1:605244085717:web:c6eaf55ea1d1dda85cd95c",
  measurementId: "G-NMLLHT3W7B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
Vue.prototype.$db= getFirestore(app);

Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)
Vue.use(VueSweetalert2);
Vue.use(VueMq)
Vue.use(VueTheMask)
Vue.use(Transitions)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
